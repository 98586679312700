import Cards from "../components/Cards"
export default function first({user, signOut}) {

    
    return <>
      
    <div className='App'>
    <div className="bg-cream-500 py-0 px-16">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="mt-2 text-right">
            <p className="text-lg font-semibold italic text-blue-300 glowing-text">
            "El verdadero viaje del conocimiento comienza con una lectura. Como decía el sabio Galileo: *'No puedes enseñar algo a un hombre; solo puedes ayudarlo a encontrarlo dentro de sí mismo.'* Cada página es una oportunidad para descubrir tu propio universo." 📚✨
            </p>
            <i className="glowing-text">
              Galileo G.
            </i>
          </div>
        </div>
      </div>
    </div>
    <Cards grado_estudiante={user.attributes['custom:Grado']} categoria_usuario={user.attributes['custom:categoria_usuario']} username={user.username}/>
    </div> 
  </>
}